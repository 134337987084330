.ql-container {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.ql-container:disabled {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: grey;
}

.ql-container:focus {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-color: var(--border-color);
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: #a3d3ff;
}

.ql-snow.ql-toolbar {
  display: block;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: inherit;
}

.ql-formats {
  text-decoration: antiquewhite;
}

.ql-editor {
  min-height: 18em;
}

/* React 18 strict mode adds 1 extra toolbar, this hides the extra toolbar */
.quill > .ql-toolbar:nth-child(2) {
  display: none !important;
}

.ql-snow .ql-picker.ql-size {
  color: inherit;
}

.ql-snow .ql-stroke {
  stroke: inherit;
}

.mention {
  color: black;
  background-color: #a3d3ff;
}

.ql-mention-list-container {
  border-radius: 15px;
  background-color: inherit;
  max-height: 80%;
  max-width: 80%;
}

.ql-mention-list-item.selected {
  color: black;
  background-color: #a3d3ff;
  text-decoration: none;
}

/* .ql-snow .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: inherit;
  color: inherit;
  background-color: inherit;
} */
